import React, { Component } from 'react';
import payslipPdf from "../../../assets/img/pdf/payslipview.pdf"

class PayslipViewer extends Component {
    render() {

        return (
            <div>
                <div style={{ textAlign: 'left', fontSize: '17px', fontWeight: 700 }} className='mt-2 mb-2'>
                    <span >Payslip For November 2024</span>
                </div>
                <iframe src={payslipPdf} style={{ borderRadius: '6px', marginRight: '8px' }} height="500" width="550" title="Iframe Example"></iframe>

            </div>
        );
    }
}

export default PayslipViewer;