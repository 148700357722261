import React, { Component } from 'react';
import { Formik, Form, Field } from 'formik';
import { Input, Select, Button } from 'antd';
import { FormGroup } from 'react-bootstrap';
import { BsFileEarmarkCheck, BsPaperclip } from "react-icons/bs";
import { updateGoalStatus, updateSubGoalStatus } from './service';
import { toast } from 'react-toastify';



const { Option } = Select;

class ProgressValueForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            newValue: '',
            status: '',
            goalId: this.props.goalId || 0,
            progressData: {},
            fileAdded: false,
        };
    }
    handleUpdate = () => {
        // Handle update logic here
    };

    handleClose = () => {
        this.setState({ fileAdded: false });
        this.props.onClose();
    };

    save = (data) => {
        if (this.props.GoalsStatusValidation) {
            updateGoalStatus(this.state.goalId, data.file, this.state.achievement, data.comments).then(res => {

                if (res.status == "OK") {
                    toast.success(res.message);

                } else {
                    toast.error(res.message);
                }
                if (res.status == "OK") {

                    this.props.updateProgressList()
                    this.handleClose();
                }
            }).catch(err => {
                console.error(err);
                toast.error("Error while updating status");
            })

        } else {


            updateSubGoalStatus(this.state.goalId, data.file, this.state.achievement, data.comments).then(res => {

                if (res.status == "OK") {
                    toast.success(res.message);

                } else {
                    toast.error(res.message);
                }
                if (res.status == "OK") {

                    this.props.updateProgressList()
                    this.handleClose();
                }
            }).catch(err => {
                console.error(err);
                toast.error("Error while updating status");
            })
        }

    }

    render() {
        const { fileAdded, newValue, status } = this.state;


        return (
            <div style={{ width: "300px" }}>
                <Formik
                    enableReinitialize={true}
                    initialValues={this.state.progressData}
                    onSubmit={this.save}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue,
                        setSubmitting
                        /* and other goodies */
                    }) => (
                        <Form>
                            <FormGroup>
                                <label>Progress %
                                </label>
                                <td>
                                    <input name="achRadius" type="range" id="achRadius" min="0" max="100" class="form-range" value={this.state.achievement} style={{ paddingTop: "10px", paddingBottom: "10px" }}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            this.setState({ achievement: value });
                                        }}
                                    />
                                    <Field
                                        name="achievement" type="number" maxLength="100" className="form-control" value={this.state.achievement}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            if (value >= 0 && value <= 100) {
                                                this.setState({ achievement: value });
                                                this.setFieldValue('achievement', value);
                                            }
                                        }}
                                        required
                                    />
                                </td>
                                {/* <Field name="achievement" type="number" className="form-control"></Field> */}
                            </FormGroup>
                            <FormGroup>
                                <label>Comment
                                </label>
                                <Field name="comments" className="form-control" placeholder="Enter Comment" component="textarea" rows="3"></Field>

                            </FormGroup>
                            <FormGroup>
                                <label htmlFor="file-upload" style={{ color: '#1890ff', fontSize: '14px', cursor: 'pointer' }}>
                                    <BsPaperclip size={17} /> Add Attachments
                                </label>
                                <input id="file-upload" style={{ display: 'none' }} name="file" type="file" className="form-control" onChange={e => {
                                    setFieldValue('file', e.currentTarget.files[0]);
                                }}></input>

                                {values.file && <BsFileEarmarkCheck size={17} style={{ color: 'green', marginLeft: '10px' }} />}
                            </FormGroup>
                            <div>
                                <Button className='mr-2' type="primary" htmlType="submit">Update</Button>
                                <Button type="default" onClick={this.handleClose}>Cancel</Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        );
    }
}

export default ProgressValueForm;